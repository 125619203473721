.phone_number_input{}

:root{
    --border-color: #699dff;
    --border-radius: 0.625rem ;
}

.phone_number_input .phone_input{
    border: 1px solid rgb(230, 235, 241); 
    background-color: #fff; 
    border-radius: var(--border-radius);
    width: 100%;
    padding: 19px 45px;
}

.phone_number_input .phone_input:focus{
    border: 1px !important;
    border-color: var(--border-color) !important;
    box-shadow: rgba(62, 121, 247, 0.5) 0px 0px 0px 2px !important;
}

.phone_number_input .phone_input_button{
    border: 1px solid rgb(230, 235, 241); 
    border-right: none; 
    background-color: transparent; 
    border-radius: var(--border-radius) 0 0 var(--border-radius)
}

.phone_number_input .phone_number_label{
    font-weight: 700;
}

.phone_number_input .phone_number_label .required_sign{
    color: #ff6b72b5;
    font-size: 14px;
}

.phone_number_input .phone_input_container{
    /* margin-top: 8px; */
}